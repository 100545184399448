.admin-handle-courts-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px 15px 0px 15px;
  border-radius: 5px;

  > .handle-courts-actions-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    > .courts-weekday-selector-wrapper {
      display: flex;
      gap: 30px;
      align-items: center;
      > .courts-in-out-toggle-wrapper {
        display: flex;
        gap: 10px;
      }
    }
    > .courts-add-wrapper {
      display: flex;
      gap: 30px;
      align-items: center;
      > .courts-buttons-wrapper {
        display: flex;
        gap: 10px;
      }
      > .courts-default-prices {
        display: flex;
        gap: 10px;
        > .courts-price-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 5px;
          >.courts-price-color {
            width: 15px;
            height: 15px;
            border-radius: 500px;
            border: var(--primary-border);
            &.red {
              background-color: var(--red);
            }
            &.blue {
              background-color: var(--blue);
            }
          }
        }
      }
    }
  }
  > .courts-table-overflow-wrapper {
    overflow-x: auto;
    padding-bottom:30px;  
    .courts-table {
      width: 100%;
      border-collapse: inherit;
      background-color: #ffffff;

      > thead {
        > tr {
          cursor: pointer;

          > th {
            &:first-child {
              padding-left: 20px;
            }
            height: 40px;
            padding: 12px 15px;
            text-align: left;
            vertical-align: middle;
            top: auto;
            bottom: auto;

            justify-content: space-between;
            > .court-name-header {
              display: flex;
            }
          }
          > th:hover {
            background-color: var(--hover-list-background);
          }
        }
      }

      > tbody {
        > tr {
          > td {
            &.court-intervals-wrapper {
              > .court-time-wrapper {

                border-right: var(--primary-border);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                align-items: center;
                border-top: var(--primary-border);
                vertical-align: middle;
                display: flex;
                justify-content: space-between;
                padding: 7.5px 15px;
                min-width: 180px;

                &.half-hour {
                  transform: translateY(50%);
                }
               
                
              }
            }
          }
        }
      }
    }
  }
}

.load-more-wrapper {
  display: flex;
  justify-content: center;
}

.edit-popup-choice {
  position: absolute;
  z-index: 2;
  padding: 15px;
  background-color: var(--toggle-button-not-active);
  border-radius: var(--border-roundness-default);
  top: 30px;
  right: 0;
  gap: 20px;
  display: none;
  flex-direction: column;
  > button {
    min-width: 200px;
  }
  &.active {
    display: flex;
  }
}
