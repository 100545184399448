.export-users-popup-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .export-users-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
    margin-bottom: 50px;
    > .export-users-permission-wrapper {
      display: flex;
      gap:10px;
    }
  }
  .export-users-btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }
}
