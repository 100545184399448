.update-delete-wrapper {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.add-clear-wrapper {
  display: flex;
  gap: 20px;
}
