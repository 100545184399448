.price-input-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center;
  > .kr-wrapper {
    position: absolute;
    right: 15px;
  }
}
