.user-handle-users-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px 15px 0px 15px;
  border-radius: 5px;
  @media screen and (max-width: 991px) {
    overflow-x: scroll;
  }
  > .users-users-load-more {
    width: 100%;
    padding: 20px 0;
    gap: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    > .users-users-spinner-wrapper {
      height: 10px;
    }
  }
  > .handle-users-actions-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;

    > .users-search-wrapper {
      display: flex;
      gap: 30px;
      > .users-search-input {
        width: 300px;
      }
    }

    > .locations-search-wrapper {
      display: flex;
      gap: 15px;

      > .locations-search-input {
        font-size: 14px;
      }
    }
  }
}

.load-more-wrapper {
  display: flex;
  justify-content: center;
}

.users-table-no-click {
  width: 100%;
  border-collapse: inherit;
  background-color: #ffffff;
  > thead {
    > tr {
      transition: all 0.2s ease-out;
      > th {
        height: 40px;
        padding: 12px 15px;
        text-align: left;
        vertical-align: middle;
        top: auto;
        bottom: auto;
      }
    }
  }

  > tbody {
    > tr {
      transition: all 0.2s ease-out;
      &:last-child {
        > td {
          border-bottom: var(--primary-border);
        }
      }
      > td {
        &:first-child {
          min-width: 40px;
          width: 40px;
        }
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-top: var(--primary-border);
        border-right: var(--primary-border);
        vertical-align: middle;

        padding: 12px 15px;
        min-width: 200px;

        &:last-child {
          border-right: none;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    > tr:hover {
      background-color: var(--hover-list-background);
      @media screen and (max-width: 991px) {
        background-color: transparent;
      }
    }
  }
}
