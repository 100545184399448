.admin-handle-coaches-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px 15px 0px 15px;
  border-radius: 5px;
  >.coaches-table-overflow-wrapper {
    overflow-x:scroll;
    > .coaches-table {
    width: 100%;
    > thead {
      > tr {
        > th {
          &:first-child {
            padding-left: 20px;
          }
          height: 40px;
          padding: 12px 15px;
          text-align: left;

          top: auto;
          bottom: auto;
        }
      }
    }
    > tbody {
      > tr {
        > td {
          align-content: flex-start;
          padding: 0px 1.5px;
          > .thirty-minuite-slot {
            height: 55px;
          }
        }
      }
    }
  }
  }
  

  > .handle-coaches-actions-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;
    @media screen and (max-width: 991px) {
      flex-wrap:wrap;
      
    }
    > .coaches-weekday-selector-wrapper {
      display: flex;
      gap: 30px;
      align-items: center;
    }
    > .coaches-search-wrapper {
      display: flex;
      gap: 30px;
      > .coaches-search-input {
        width: 300px;
      }
    }

    > .locations-search-wrapper {
      display: flex;
      gap: 15px;

      > .locations-search-input {
        font-size: 14px;
      }
    }
  }
}
.nested-admin-coach-times-table {
  > tbody {
    > tr {
      > td {
        &:nth-child(1) {
          width: 20%;
        }
        padding: 0px 0px;

        > .time-item-wrapper {
          margin-bottom: 1.5px;
          @media screen and (max-width: 991px) {
            min-width:150px;
            
          }
          > .time-content-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 3px;
            max-height: 55px;
          }
          &.selected-slot {
            background-color: var(--primary-button-background);
            color: var(--primary-button-label);
          }
          &.not-available {
            background-color: var(--secondary-background-card);
          }
          height: 55px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          border-radius: var(--border-roundness-default);
          border: var(--primary-border);
          background-color: #ffffff;
          padding: 7px 5px;
          min-width: 66px;
          max-width: 150px;
          &.slot-booked {
            color: #ffffff;
            background-color: var(--primary-button-background);
          }
        }
      }
    }
  }
}

.load-more-wrapper {
  display: flex;
  justify-content: center;
}
