@mixin circle($size: 100%) {
    width: $size;
    height: $size;
    min-width: $size;
    min-height: $size;
    max-width: $size;
    max-height: $size;
    border-radius: 50%;
}

