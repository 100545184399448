@import "./variables.scss";

.line-divider {
  height: 1px;
  width: 100%;
  background-color: var(--primary-border-color);
  &.marg-10 {
    margin: 10px 0px;
  }
  &.marg-20 {
    margin: 20px 0px;
  }
  &.marg-30 {
    margin: 30px 0px;
  }
  &.dark {
    background-color: rgb(209, 208, 208);
  }
}
