.add-time-batch-button-wrapper {
    margin-top: 10px;
    display: flex;
    justify-content: start;
  
}
.discount-price-wrapper {
  display: flex;
  gap: 20px;
  align-items: center;
}

.time-batch-looper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
