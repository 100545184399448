@import "./variables.scss";
@import "./circle.scss";
@import "./normalize.scss";
@import "./utility.scss";

@font-face {
  font-family: "Switzer";
  src: url("../fonts/Switzer-Variable.woff2") format("woff2"),
    url("../fonts/Switzer-Variable.ttf") format("truetype");
}

@font-face {
  font-family: "Futura";
  src: url("../fonts/FUTURA65MEDIUM.TTF") format("truetype");
}
body {
  min-width: 1300px;
  padding: 0px 0px 30px 0px;
  font-family: "Switzer", sans-serif;
  color: var(--primary-text);
  background-color: var(--primary-background);
  @media screen and (max-width: 991px) {
    min-width: 0;
  }
}

button {
  background-color: var(--primary-button-background);
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 1000px;
  color: var(--primary-button-label);
  padding: 9px 15px;
  height: 35px;
  cursor: pointer;
  &.slim {
    height: 25px;
    width: 20px;
  }
  &.wide {
    min-width: 200px;
  }
  transition: all 0.2s ease-out;

  &:hover {
    background-color: var(--primary-button-background-hover);
  }
}

.toggle-button {
  cursor: pointer;
  padding: 3px 15px;
  border-radius: 40px;
  height: 25px;
  background-color: var(--toggle-button-not-active);
  color: var(--hover-button-label-light);
  &:hover {
    background-color: var(--toggle-button-not-active-hover);
  }
  &.active {
    background-color: var(--toggle-button-active);
    color: var(--primary-button-label);
  }
  &.active-disable {
    background-color: var(--delete-color);
    color: white;
  }
}

a {
  text-decoration: underline;
  color: var(--toggle-button-active);
  &:hover {
    color: var(--toggle-button-active-hover);
  }
  cursor: pointer;
  &.danger {
    color: var(--delete-color);
    &:hover {
      color: var(--delete-color);
    }
  }
}

button:disabled {
  background-color: rgb(236, 236, 236);
  color: var(--secondary-text);
  cursor: not-allowed;
}
.pointer {
  cursor: pointer;
}
.deleteButton {
  background-color: transparent;
  color: var(--delete-color);
  padding: 0;
  &:hover {
    background-color: transparent;
  }
}

input,
select {
  border-radius: 40px;
  border: var(--primary-border);
  height: 35px;
  background-color: var(--secondary-background);
}

input:focus {
  outline-color: var(--toggle-button-active);
  @media screen and (max-width: 991px) {
    outline-color: transparent;
  }
}

select:focus {
  outline: none;
}

input {
  padding: 15px 15px;
  &.short {
    width: 100px;
  }
}
// input.on-transparent {
//   background-color: transparent;
//   border: none;
//   border-radius: 0px;
//   padding: 0px;
// }

select {
  padding: 0px 30px 0px 15px;
  background: url("../assets/images/select-arrow.svg") no-repeat right;
  background-position: center right 15px;
  appearance: none;
  background-color: var(--secondary-background);
  -webkit-appearance: none;
  -moz-appearance: none;
  &.long {
    width: 100%;
  }
  > .placeholder {
    color: var(--secondary-text);
  }
}

.no-wrap {
  white-space: nowrap;
}
.edit-component {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  padding: 7px 5px;
  border-radius: 2px;
  margin-left: auto;
  position: relative;
  > .edit-dot {
    width: 5px;
    height: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: var(--secondary-text);
    cursor: pointer;
  }
}

.edit-component:hover {
  background-color: var(--primary-button-background);
  > .edit-dot {
    background-color: var(--primary-button-label);
  }
}

.toggle {
  position: relative;
  cursor: pointer;
  > input {
    position: absolute;
    opacity: 0;
    width: 0px;
    height: 0px;
  }
  > .slider {
    position: relative;
    display: block;
    width: 50px;
    height: 25px;
    padding: 2px;
    border-radius: 40px;
    background-color: var(--toggle-button-not-active);
    transition: 0.4s;
  }
  .slider > i {
    position: relative;
    margin-top: 4px;
    margin-left: 4.5px;
  }
  > .slider:before {
    content: "";
    position: absolute;
    height: 21px;
    width: 21px;
    background-color: #ffffff;
    border-radius: 50%;
    transition: 0.4s;
    top: 2px;
    left: 2px;
  }
  > input:checked + .slider {
    background-color: var(--toggle-button-active);
  }
  > input:checked + .slider:before {
    transform: translateX(25px);
  }
  > input:checked + .slider > i {
    transform: translateX(25.5px);
  }
}
.activity-color {
  position: relative;

  > input {
    cursor: pointer;
    position: absolute;
    opacity: 0;
    width: 40px;
    height: 40px;
    z-index: 2;
  }
  > .color {
    cursor: pointer;
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    border-radius: 40px;
    border: 1px solid transparent;
    &.activity-color-1 {
      background-color: var(--activity-color-1);
    }
    &.activity-color-2 {
      background-color: var(--activity-color-2);
    }
    &.activity-color-3 {
      background-color: var(--activity-color-3);
    }
    &.activity-color-4 {
      background-color: var(--activity-color-4);
    }
  }
  > input:checked + .color {
    border: 2px solid var(--primary-text);
  }
}

.activity-color-blob {
  width: 25px;
  height: 25px;
  border-radius: 40px;
  border: 1px solid transparent;
  &.activity-color-1 {
    background-color: var(--activity-color-1);
  }
  &.activity-color-2 {
    background-color: var(--activity-color-2);
  }
  &.activity-color-3 {
    background-color: var(--activity-color-3);
  }
  &.activity-color-4 {
    background-color: var(--activity-color-4);
  }
}
.price-wrapper {
  width: 15px;
  height: 15px;
  border-radius: 500px;
  border: var(--primary-border);
  &.red {
    background-color: var(--red);
  }
  &.blue {
    background-color: var(--blue);
  }
}

.css-1dimb5e-singleValue {
  color: var(--toggle-button-active) !important;
}

.search-input-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  > input {
    padding-left: 40px;
  }

  > .search-icon {
    position: absolute;
    left: 15px;
  }
}

.Toastify__close-button:hover {
  background-color: transparent;
}

.cross-img {
  padding: 2px;
  margin-right: -2px;
  border-radius: 40px;
  border: 1px solid rgba(244, 3, 3, 0.15);
  &:hover {
    border: 1px solid rgba(244, 3, 3, 0.3);
  }
}

.desktop-text {
  display: flex;
  @media screen and (max-width: 991px) {
    display: none;
  }
}
.mobile-text {
  display: none;
  @media screen and (max-width: 991px) {
    display: flex;
  }
}

@media print {
  @page {
    size: A4 landscape;
    margin: 10mm;
  }

  body {
    zoom: 0.47;
  }
  body * {
    visibility: hidden;
  }

  .print-section,
  .print-section * {
    visibility: visible;
  }

  .print-section {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.text-color-blue {
  color: var(--blue);
}
.text-color-red {
  color: var(--red);
}

.frozen-user-icon {
  width: 15px;
}

.frozen {
  color: red;
}
