.input-currency-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  > .currency-wrapper {
    position: absolute;
    right: 15px;
  }
}

.color-price-wrapper {
    display:flex;
    align-items: center;
    gap:5px;
  .color-ring {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    &.red {
      background-color: var(--red);
    }
    &.blue {
      background-color: var(--blue);
    }
  }
}
