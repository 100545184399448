.choose-users-popup-wrapper {
  min-height: 200px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  > .user-input-btn-wrapper {
    display: flex;
    gap: 10px;
    > .search-input-wrapper {
      width: 100%;
      > input {
        width: 100%;
      }
    }
  }
  > .player-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    transition: all 0.2s ease-out;
    border-radius: var(--border-roundness-default);
    > .player-name {
      display: flex;
      gap: 10px;
      align-items: center;

      
    }
    > .player-id {
      display: flex;
      min-width: 100px;
      justify-content: end;
    }
    > .choose-player {
      min-width: 100px;
      display: none;
    }
    &:hover {
      background-color: var(--secondary-background-card);

      > .player-id {
        display: none;
      }
      > .choose-player {
        display: flex;

        justify-content: end;
      }
    }
  }
}
