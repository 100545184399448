.red-button {
  background: var(--delete-color);
}

// .confirm-button-wrapper {
//     width:100%;
//     display:flex;
//     justify-content: center;
// }
.child-flexer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 20px;
}
