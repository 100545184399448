.week-day-selector {
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
}
.input-desktop {
  width: 135px;
}
.react-datepicker-popper {
  margin-left: 70px;
}
.react-datepicker__triangle {
  left: 62.5px !important;
}

.absolute-calendar {
  position: absolute;
  left: 15px;
  pointer-events: none;
}

.react-datepicker__input-container > input {
  padding-left: 35px;
}
