.slot-history-wrapper {
  display: flex;
  gap: 30px;
  flex-direction: column;
  padding-left: 30px;
  border-left: 1px solid var(--toggle-button-not-active);
  
  > .slot-history-item {
    margin-top:-4px;
    position: relative;
    display:flex;
    flex-direction: column;
    gap: 5px;
    > .slot-history-blob {
      top: 4px;
      left: -35.5px;
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: var(--toggle-button-active);
      border-radius: 50%;
    }
  }
}
