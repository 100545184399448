.week-selector {
  display: flex;
  align-items: center;
  gap: 10px;
}
.week-input-desktop {
  width: 125px;
  padding-left:40px !important;
}

.week-v {
  position: absolute;
  z-index: 2;
  left: 30px;
  top: 8px;
  pointer-events: none;
}

.relative-picker {
  position: relative;
}
