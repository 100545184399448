.activity-participants-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  > .activity-participant-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    gap: 3px;
    border-bottom: 1px solid #e0e0e0;
    padding-bottom: 20px;
    margin-bottom: 10px;
  }
}

.checkin-actions-wrapper {
  display: flex;
  gap: 3px;
  flex-direction: column;
  align-items: flex-end;
}

