.admin-handle-statistic-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px 15px 0px 15px;
  border-radius: 5px;

  > .handle-statistic-actions-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;

    > .chart-toggle-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
  > .chart-wrapper {
    display: flex;
    justify-content: center;
    max-height: 750px;
    padding-bottom: 50px;
  }
}
