.admin-handle-specific-user-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px 15px 0px 15px;
  border-radius: 5px;

  > .handle-specific-user-actions-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;

    > .history-toggle-wrapper {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
}

.history-table {
  width: 100%;
  border-collapse: inherit;
  background-color: #ffffff;

  > thead {
    > tr {
      transition: all 0.2s ease-out;
      > th {
        height: 40px;
        padding: 12px 15px;
        text-align: left;
        vertical-align: middle;
        top: auto;
        bottom: auto;
      }
    }
  }

  > tbody {
    > tr {
      cursor: pointer;

      &.non-clickable {
        cursor: default;
        &:hover {
          background-color: #ffffff;
        }
      }
      transition: all 0.2s ease-out;
      > td {
        &.checked-in-specific {
          background-color: var(--checked-in-color);
        }
        &.paid {
          background-color: var(--checked-in-color);
        }
        &.no-show {
          background-color: var(--no-show-color);
        }
        &.semi-no-show {
          background-color: var(--semi-no-show-color);
        }
        &.cancelled {
          background-color: var(--passed-unbooked-color);
        }
        &.confirmed {
          background-color: var(--booked-color);
        }
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-top: var(--primary-border);
        border-right: var(--primary-border);
        vertical-align: middle;

        padding: 12px 15px;
        min-width: 200px;

        &:last-child {
          border-right: none;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    > tr:hover {
      background-color: var(--hover-list-background);
    }
  }
}
