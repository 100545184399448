.remove-cc-email {
    position:absolute;
    left:115px;
    top:4.9px;
    width:10px;
    height:10px;
}
.cc-wrapper {
    position:relative;
   
}