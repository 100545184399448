@import "../../styles/circle";
.daily-comment-banner {
  border-radius: 0px 0px 5px 5px;
  background-color: var(--toggle-button-not-active);
  padding: 5px 10px;
}
.navbar {
  position: sticky;
  z-index: 100;
  display: flex;
  background: var(--secondary-background);
  padding: 0px 10px;
  height: 37px;
  margin-top: 10px;
  border-radius: 5px;
  
  > .nav-items--wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0px 5px;

    > .nav-items {
      display: flex;
      align-items: center;
      gap: 20px;
      @media screen and (max-width: 991px) {
        gap: 15px;
        overflow-x:scroll;
      }
      > .my-profile-hover:hover {
        color: var(--blue);
      }
    }
  }
}

.nav-item {
  &.nav-item-settings {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: var(--primary);
    @include circle(40px);
    border: var(--border-primary);

    &.active {
      background: #fff;
      color: yellowgreen;
    }
  }

  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-text);
  text-decoration: none;

  &.active {
    color: var(--toggle-button-active);
  }
}

.nav-dropdown {
  margin-right: -8px;
  .css-1ookgdl-ValueContainer {
    padding: 0px;
  }
}
