.comment-popup-wrapper {
  min-height: 400px;
  min-width: 500px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  > .comment-button-input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
    > .handle-comments-date-wrapper {
      display: flex;
      gap: 10px;
      align-items: flex-end;
    }
    > .comment-input-wrapper {
      width: 100%;
      > input {
        width: 100%;
      }
    }
    > .comments-list {
      margin-top: 10px;
      padding-top: 10px;
      width: 100%;
      border-top: var(--primary-border);
      max-height: 500px;
      overflow-y: auto;
      > .comment-item {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 100%;
        border-bottom: var(--primary-border);
        padding-bottom: 15px;
        padding-top:10px;
        > .comment-item-input-btn-wrapper {
          width: 100%;
          display: flex;
          gap: 10px;
          > input {
            width: 100%;
          }
        }
      }
    }
  }
}
