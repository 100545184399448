.user-bookings-page-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  @media screen and (max-width: 991px) {
    flex-direction: column;
  }
  .user-bookings-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff;
    padding: 20px 15px 0px 15px;
    border-radius: 5px;
    width: 100%;
    @media screen and (max-width: 1500px) {
      max-width: 1000px;
    }
    > .courts-table-overflow-wrapper {
      overflow-x: auto;
      padding-bottom: 15px;
      > .courts-table {
        width: 100%;
        > tbody {
          > tr {
            > td {
              align-content: flex-start;
              padding: 0px 1.5px;
            }
          }
        }
      }
    }

    > .user-bookings-declarations {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 991px) {
        display: none;
      }
      > .declarations-wrapper {
        display: flex;
        gap: 20px;
        color: var(--secondary-text);
      }
    }
    > .mobile-info-wrapper {
      @media screen and (min-width: 992px) {
        display: none;
      }
      width: 100%;
      padding-top: 15px;
      border-top: var(--primary-border);
    }
    > .bookings-action-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      align-items: center;
      flex-wrap: wrap;
      @media screen and (max-width: 991px) {
        gap: 15px;
      }
      > .bookings-weekday-selector-wrapper {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: 991px) {
          flex-direction: row-reverse;
        }
        > .user-booking-court-type {
          display: flex;
          gap: 10px;
          > .pseudo-button {
            background-color: var(--toggle-button-not-active);
            display: flex;
            justify-content: center;
            align-items: center;
            border: none;
            border-radius: 40px;
            color: var(--secondary-button-label);
            padding: 9px 15px;
            height: 35px;
            &.smaller {
              height: 25px;
            }
            &.wide {
              min-width: 200px;
            }
            &.active {
              background-color: var(--toggle-button-active);
              color: var(--primary-button-label);
            }
          }
        }
      }
      > .bookings-add-wrapper {
        display: flex;
        gap: 15px;
        flex-wrap: wrap;
        > .coaches-select {
          min-width: 150px;
        }

        > .bookings-default-prices {
          display: flex;
          gap: 20px;
          > .bookings-price-wrapper {
            padding: 9px 15px;
            border: var(--primary-border);
            display: flex;
            justify-content: space-between;
            min-width: 200px;
            border-radius: 500px;
          }
        }
      }
    }
  }
  .user-booking-maker-wrapper {
    overflow: hidden;
    min-width: 350px;
    max-width: 370px;
    @media screen and (max-width: 991px) {
      max-width: none;
      min-width: 300px;
    }
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-background);
    border-radius: var(--border-roundness-default);
    > .user-booking-top-wrapper {
      display: flex;
      gap: 15px;
      flex-direction: column;
      background-color: var(--secondary-background);
      padding: 15px;

      > .user-booking-booking-type {
        display: flex;
        flex-direction: column;
        gap: 10px;
        border: 1px solid transparent;
        border-radius: var(--border-roundness-default);
        &.selected {
          border: 1px solid var(--primary-button-background);
        }
        > .user-booking-specifics {
          display: flex;
          flex-direction: column;
          padding: 5px 0px;
          background-color: var(--secondary-background-card);
          border-radius: var(--border-roundness-default);
          > .user-booking-info-row-wrapper {
            padding: 5px 15px;
            display: flex;
            justify-content: space-between;
            &.flex-down {
              flex-direction: column;
              gap: 10px;
            }
            > .user-booking-action {
              width: 100%;
              margin-top: 10px;
              > .full-width {
                width: 100%;
              }
            }
          }
        }
      }
    }

    > .user-booking-bottom-default {
      border-top: 1px solid var(--primary-border-color);
      display: flex;
      gap: 15px;
      flex-direction: column;
      background-color: var(--secondary-background);
      padding: 15px;
      > .user-booking-specifics {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        background-color: var(--secondary-background-card);
        border-radius: var(--border-roundness-default);
        > .user-booking-info-row-wrapper {
          padding: 5px 15px;
          display: flex;
          justify-content: space-between;
          > .info-row-inner-gap {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          > .user-booking-action {
            width: 100%;
            > .full-width {
              width: 100%;
            }
          }
        }
      }
      > .user-queuetime-wrapper {
        padding: 15px;
        display: flex;
        gap: 5px;
        flex-direction: column;
        border-radius: var(--border-roundness-default);
        background-color: var(--secondary-background-card);
        > .user-queuetime-item {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          gap:10px;
          >.user-queuetime-info-row-wrapper {
            display:flex;
            justify-content: space-between;
          }
        }
      }
    }
    > .user-booking-bottom-wrapper {
      padding: 15px;
      border-radius: var(--border-roundness-default);
      border: 1px solid var(--primary-button-background);
      height: 100%;
    }
  }
}

.nested-courts-table {
  > tbody {
    > tr {
      > td {
        &:nth-child(1) {
          width: 20%;
        }
        padding: 1.5px 0px;

        > .summary-item-wrapper {
          transition: all 0.2s ease-out;
          text-align: center;
          &.is-available {
            @media screen and (min-width: 992px) {
              &:hover {
                border: 1px solid var(--primary-button-background);
              }
            }
          }

          > .summary-content-wrapper {
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-direction: column;
            line-height: 13px !important;
          }
          &.matches-search {
            border: 1px solid #007aff;
          }
          &.no-match {
            opacity: 0.3;
          }
          &.selected-slot {
            border: 1px solid var(--primary-button-background);
          }
          &.not-available {
            background-color: #E4E4E9;
            border-color: #E4E4E9 ;
            color:var(--secondary-text);
          }
          &.activity-color-1 {
            background-color: var(--activity-color-1);
          }
          &.activity-color-2 {
            background-color: var(--activity-color-2);
          }
          &.activity-color-3 {
            background-color: var(--activity-color-3);
          }
          &.activity-color-4 {
            background-color: var(--activity-color-4);
          }
          height: 60px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          border-radius: var(--border-roundness-default);
          border: var(--primary-border);
          background-color: #ffffff;
          padding: 7px 5px;
          min-width: 66px;

          &.slot-booked {
            color: #ffffff;
            background-color: var(--primary-button-background);
          }
        }
      }
    }
  }
}

.taken-icon {
  width: 15px;
  height: 15px;
}
