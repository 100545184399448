.admin-bookings-page-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 15px;
  .admin-bookings-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff;
    padding: 20px 15px 0px 15px;
    border-radius: 5px;
    width: 100%;
    > .courts-table-overflow-wrapper {
      overflow-x: auto;
      padding-bottom: 15px;
      min-height: 80vh;
      > .courts-table {
        width: 100%;
        > thead {
          > tr {
            > th {
              &.padding-right-20px {
                padding-right: 20px;
              }
            }
          }
        }
        > tbody {
          > tr {
            > td {
              align-content: flex-start;
              padding: 0px 1.5px;
              &.pushed-down-court {
                padding-top: 27.5px;
              }

              .nested-courts-table {
                > tbody {
                  > tr {
                    > td {
                      &:nth-child(1) {
                        width: 20%;
                      }
                      padding: 1.5px 0px;

                      > .summary-item-wrapper {
                        width: auto;
                        transition: all 0.2s ease-out;
                        position: relative;
                        &.push-down-15px {
                          margin-top: 14.5px;
                        }

                        &.is-available {
                          &:hover {
                            border: 1px solid var(--primary-button-background);
                          }
                        }
                        > .inclueds-prize {
                          position: absolute;
                          top: 8.2px;
                          right: 15px;
                          scale: 0.75;
                        }
                        .booking-has-coach {
                          position: absolute;
                          top: 8px;
                          right: 35px;
                          scale: 0.75;
                        }
                        > .summary-content-wrapper {
                          display: flex;
                          align-items: center;
                          justify-content: center;
                          flex-direction: column;
                          position: relative;
                        }
                        > .comment-icon {
                          position: absolute;
                          left: 8px;
                          top: 8px;
                        }
                        &.matches-search {
                          border: 1px solid #007aff;
                        }
                        &.no-match {
                          opacity: 0.3;
                        }
                        &.selected-slot {
                          border: 1px solid var(--primary-button-background);
                        }
                        &.highlight-selected {
                          color: var(--toggle-button-active) !important;
                        }
                        &.highlight-interval {
                          background-color: var(
                            --toggle-button-not-active
                          ) !important;
                        }
                        &.not-available {
                          background-color: #e4e4e9;
                          border-color: #e4e4e9;
                        }
                        &.booking-in-progress {
                          background-color: rgb(83, 209, 213);
                        }
                        &.checked-in-slot {
                          background-color: var(--checked-in-color);
                        }
                        &.paid-slot {
                          background-color: var(--checked-in-color);
                        }
                        &.paid-not-checked-in-slot {
                          background-color: var(--booked-color);
                        }
                        &.no-show-slot {
                          background-color: var(--no-show-color);
                        }
                        &.semi-no-show-slot {
                          background-color: var(
                            --semi-no-show-color
                          ) !important;
                        }
                        &.marked-for-movement {
                          background-color: red !important;
                        }
                        &.selected-user {
                          border: 1px solid var(--primary-button-background);
                          color: var(--primary-text);
                        }
                        &.has-booking {
                          background-color: var(--booked-color);
                        }
                        &.passed-unbooked {
                          background-color: var(--passed-unbooked-color);
                          opacity: 0.3;
                        }
                        &.activity-color-1 {
                          background-color: var(--activity-color-1);
                        }
                        &.activity-color-2 {
                          background-color: var(--activity-color-2);
                        }
                        &.activity-color-3 {
                          background-color: var(--activity-color-3);
                        }
                        &.activity-color-4 {
                          background-color: var(--activity-color-4);
                        }
                        height: 55px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        align-items: center;
                        border-radius: var(--border-roundness-default);
                        border: var(--primary-border);
                        background-color: #ffffff;
                        padding: 7px 5px;
                        min-width: 150px;

                        &.slot-booked {
                          color: #ffffff;
                          background-color: var(--primary-button-background);
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    > .admin-bookings-declarations {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      > .admin-bookings-oversight-info-wrapper {
        display: flex;
        gap: 10px;
        > .admin-bookings-booking-info-wrapper {
          display: flex;
          gap: 5px;
        }
      }
      > .admin-bookings-declerations-buttons {
        display: flex;
        flex-direction: row;
        gap: 5px;
        flex-wrap: wrap;
        > .toggle-flex-between {
          display: flex;
          justify-content: center;
          gap: 10px;
          align-items: center;
        }
        > .chosen-user-info-wrapper {
          display: flex;
          gap: 5px;
          justify-content: center;
          align-items: center;

          &.frozen {
            color: red;
          }
        }
      }
    }
    > .admin-bookings-action-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      align-items: center;
      @media screen and (max-width: 992px) {
        flex-direction: column;
        gap: 20px;
        align-items: start;
      }
      > .bookings-weekday-selector-wrapper {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
        @media screen and (max-width: 992px) {
          flex-wrap: wrap;
          gap: 5px;
        }
        > .admin-booking-court-type {
          display: flex;
          gap: 10px;
        }
      }
      > .admin-bookings-add-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        @media screen and (max-width: 992px) {
          flex-wrap: wrap;
        }
      }
    }
  }
  .admin-booking-maker-wrapper {
    overflow: hidden;
    min-width: 370px;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-background);
    border-radius: var(--border-roundness-default);
    > .admin-booking-top-wrapper {
      display: flex;
      gap: 15px;
      flex-direction: column;
      background-color: var(--secondary-background);
      padding: 15px;

      > .admin-booking-court-type {
        display: flex;
        gap: 15px;
        > .pseudo-button {
          background-color: var(--toggle-button-not-active);
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 40px;
          color: var(--secondary-button-label);
          padding: 9px 15px;
          height: 35px;
          &.smaller {
            height: 25px;
          }
          &.wide {
            min-width: 200px;
          }
          &.active {
            background-color: var(--toggle-button-active);
            color: var(--primary-button-label);
          }
        }
      }
      > .admin-booking-booking-type {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > .admin-booking-specifics {
          display: flex;
          flex-direction: column;
          padding: 5px 0px;
          background-color: var(--secondary-background-card);
          border-radius: var(--border-roundness-default);
          > .admin-booking-info-row-wrapper {
            padding: 5px 15px;
            display: flex;
            justify-content: space-between;
            > .admin-booking-action {
              width: 100%;
              margin-top: 10px;
              > .full-width {
                width: 100%;
              }
            }
          }
        }
      }
    }

    > .admin-booking-bottom-default {
      border-top: 1px solid var(--primary-border-color);
      display: flex;
      gap: 15px;
      flex-direction: column;
      background-color: var(--secondary-background);
      padding: 15px;
      > .admin-booking-specifics {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        background-color: var(--secondary-background-card);
        border-radius: var(--border-roundness-default);
        > .admin-booking-info-row-wrapper {
          padding: 5px 15px;
          display: flex;
          justify-content: space-between;
          > .info-row-inner-gap {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          > .admin-booking-action {
            width: 100%;
            > .full-width {
              width: 100%;
            }
          }
        }
      }
    }
    > .admin-booking-bottom-wrapper {
      padding: 15px;
      border-radius: var(--border-roundness-default);
      border: 1px solid var(--primary-button-background);
      height: 100%;
    }
  }
}

.taken-icon {
  width: 15px;
  height: 15px;
}

.select-style {
  background-color: transparent;
  color: var(--secodary-button-label);
  border: var(--primary-border);
  &:hover {
    background-color: transparent;
  }
}

.admin-actions-setting {
  min-width: 150px;
  justify-content: start;
}
