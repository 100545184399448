.login-wrapper {
  > .login-form {
    > .general-auth-input-wrapper {
      border-radius: var(--border-roundness-default);
      background-color: var(--primary-background);
      padding: 15px;
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 20px;
      > .general-label-input {
        display: flex;
        flex-direction: column;
        gap: 10px;
        position: relative;
        >.general-label-input-flex {
          display: flex;
          flex-wrap:wrap;
          gap:10px
        }
        > .input-label {
          display: flex;
          gap: 5px;
        }
        > .password-input-wrapper {
          position: relative;
          display: flex;
          align-items: center;
          > input {
            width: 100%;
          }
          > .password-toggle-icon {
            position: absolute;
            right: 15px;
            cursor: pointer;
          }
        }
      }
    }
    > .login-bottom-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 20px;
      @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
      }
    }
  }
}
