.activity-colors-wrapper {
  display: flex;
  gap: 15px;
}
.activity-name {
  display: flex;
  gap: 10px;
  align-items: center;
  > .activity-name-blob {
    display: flex;
    align-items: center;
    border-radius: 50px;
    height: 25px;
    width: 25px;
    border:1px solid var(--primary-text);
    &.activity-color-1 {
      background-color: var(--activity-color-1);
    }
    &.activity-color-2 {
      background-color: var(--activity-color-2);
    }
    &.activity-color-3 {
      background-color: var(--activity-color-3);
    }
    &.activity-color-4 {
      background-color: var(--activity-color-4);
    }
   
  }
}


.activity-select {
  min-width: 200px;
}
.activity-times-wrapper {
  display:flex;
  justify-content: flex-start;
  gap:10px;
}