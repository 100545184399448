.visual-art-match-layout {
  font-family: "Futura";
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #194525;
  color: #e3c804 !important;
  > .minute-countdown-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    &.activate {
      opacity: 1 !important;
      transition: opacity 0.5s ease-in;
    }
    &.deactivate {
      opacity: 0 !important;
      transition: opacity 0.5s ease-out;
    }
    > .countdown-text {
      font-size: 65vh;
      text-align: center;
      margin-top: 2rem;
    }
  }
  > .sponsor-logos-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
    text-align: center;
    > img {
      width: 100%;
      max-width: 70vw;
      max-height: 60vh;
      object-fit: contain;
    }
    opacity: 0;
    &.activate {
      opacity: 1 !important;
      transition: opacity 0.5s ease-in;
    }
    &.deactivate {
      opacity: 0 !important;
      transition: opacity 0.5s ease-out;
    }
  }
  > .visual-art-match-wrapper {
    position: absolute;
    top: 0;
    left: 0;

    width: 100vw;

    padding: 1%;
    opacity: 0;
    &.activate {
      opacity: 1 !important;
      transition: opacity 0.5s ease-in;
    }
    &.deactivate {
      opacity: 0 !important;
      transition: opacity 0.5s ease-out;
    }
    > .result-content {
      margin: 0 1%;
      > .result-top-wrapper {
        padding: 1% 0;
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        gap: 20px;
        border-bottom: 2px solid #e3c804;
        margin-bottom: 3%;
        > .current-time,
        > .current-date {
          font-size: 8vh;
        }
        > img {
          width: 35%;
          object-fit: contain;
        }
      }
      > .result-bottom-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 5px;
        padding-bottom: 3%;
        border-bottom: 2px solid #e3c804;
        min-height: 70vh;
        > .top-players-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          > .top-player {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #153b23;
            padding: 1.5rem;
            font-size: 10vh;
            line-height: 10vh;
          }
        }
        > .center-vs-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 10px;
          margin-bottom: 10px;
          font-size: 6vh;
          line-height: 7vh;
        }
        > .bottom-players-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          > .bottom-player {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #153b23;
            padding: 1.5rem;
            font-size: 10vh;
            line-height: 10vh;
          }
        }
        > .coach-wrapper {
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-top: 30px;
          > .coach-player {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: #153b23;
            padding: 1.5rem;
            font-size: 10vh;
            line-height: 10vh;
          }
        }
      }
    }
  }
}

.visual-art-logo {
  max-height: 150px;
  max-width: 40vw;
}
