:root {
  --primary-background: #f2f2f7;
  --secondary-background: #ffffff;
  --secondary-background-card: #f4f4f6;

  --primary-button-background: #30389c;
  --primary-button-background-hover: #272d7c;

  --primary-button-label: #ffffff;

  --hover-list-background: #f0f7f4;
  --hover-button-label-light: #090e40;

  --toggle-button-active: #007aff;
  --toggle-button-active-hover: #016de4;
  --toggle-button-not-active: #deeaff;
  --toggle-button-not-active-hover: #c9ddff;

  --primary-text: #020523;
  --secondary-text: #818094;

  --highlighted-text: #007aff;
  --primary-border: 1px solid #f2f2ff;
  --primary-border-color: #f2f2ff;
  --border-roundness-default: 5px;

  --success-color: #0ece38;
  --delete-color: #ff3b30;

  --red: #f40303;
  --blue: #007aff;

  --booked-color: rgba(25, 215, 106, 0.2);
  --checked-in-color: rgba(135, 218, 179, 1);
  --no-show-color: rgba(221, 89, 89, 1);
  --semi-no-show-color: rgba(221, 89, 89, 0.2);
  --passed-unbooked-color: rgba(231, 231, 234, 1);

  --activity-color-1: rgba(205, 233, 235, 1);
  --activity-color-2: rgba(249, 161, 58, 1);
  --activity-color-3: rgba(249, 217, 50, 1);
  --activity-color-4: rgba(135, 138, 218, 1);
}

/*font utility classes*/
.bg-primary {
  background: var(--primary-background);
}
.bg-secondary {
  background: var(--secondary-background);
}
.bg-secondary-card {
  background: var(--secondary-background-card);
}

.primary-text {
  color: var(--primary-text);
}
.secondary-text {
  color: var(--secondary-text);
}

.highlighted-text {
  color: var(--highlighted-text);
}
.text-danger {
  color: var(--delete-color);
}
.text-s {
  font-size: 13px;
  line-height: 150%;
  font-weight: 500;
}

.text-m {
  font-size: 14px;
  line-height: 150%;
  font-weight: 500;
}

.text-l {
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
}

.text-xl {
  font-size: 30px;
  line-height: 120%;
  font-weight: 500;
}

.text-xxl {
  font-size: 60px;
  line-height: 120%;
  font-weight: 500;
}

.text-regular {
  font-weight: 400;
}
.text-medium {
  font-weight: 500;
}
.text-semi-bold {
  font-weight: 600;
}
.text-bold {
  font-weight: 700;
}
