.checked-in {
  color: var(--success-color);
}
.admin-handle-guest-selection-type {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  > .admin-handle-guest-selection-buttons {
    display: flex;
    gap: 10px;
  }
  > .toggle-flex-between {
    display: flex;
    justify-content: center;
    gap: 10px;
    align-items: center;
  }
}

.searched-guests-wrapper {
  > .player-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    transition: all 0.2s ease-out;
    border-radius: var(--border-roundness-default);
    > .player-name {
      display: flex;
      gap: 10px;
      align-items: center;
      &.frozen {
        color: red;
      }
    }
    > .player-id {
      display: flex;
    }
    > .choose-player {
      display: none;
    }
    &:hover {
      background-color: var(--secondary-background-card);
      > .player-id {
        display: none;
      }
      > .choose-player {
        display: flex;
      }
    }
  }
}

.add-guest-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  > .add-guest-inputs-wrapper {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    > input {
      width: calc(50% - 5px);
    }
    > .add-guest-button-wrapper {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      width: calc(50% - 5px);
    }
  }
  > .add-guest-family-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 10px;
    > .add-guest-family-field {
      width: 50%;
      display: flex;
      flex-direction: column;
      gap: 10px;
      > .add-guest-family-input-wrapper {
        position: relative;
        > input {
          width: 100%;
        }
        > .x {
          position: absolute;
          top: 12px;
          right: -25px;
        }
      }
    }
    > input {
      width: calc(50% - 5px);
    }
  }
}

.checkin-text-wrapper {
  display: flex;
  gap: 10px;
}
