.availability-batch-wrapper {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  > .availability-batch-core-wrapper {
    display: flex;
    gap: 10px;
    align-items: center;

    >.price-input-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        position:relative;
        > input {
            width: 100px;
        }
        >.kr-tag {
            position:absolute;
            right:10px;
            
        }
    }
    > .checkbox-container {
      display: flex;
      align-items: center;
      justify-content: center;

      > .availability-batch-check-label {
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--toggle-button-not-active);
        cursor: pointer !important;
        user-select: none;
        border-radius: 50%;
      }
      > .availability-batch-checkbox {
        opacity: 0;
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        cursor: pointer;
        &.availability-batch-checkbox:checked + .availability-batch-check-label {
          background-color: var(--toggle-button-active);
          color: white;
        }
      }
    }
  }

  > .add-remove-wrapper {
    position: relative;
    left: auto;
    display: flex;
    justify-content: flex-end;
    gap: 15px;
  }
}
.exception-date-toggle-wrapper {
  display: flex;
  justify-content: space-between;
  > .exception-date-pickers-wrapper {
    display: flex;
    gap: 10px;
  }
}

.availability-batch-price-choice-wrapper {
  display: flex;
  margin-right:5px;
  justify-content: flex-start;
  align-items: center;
  gap:10px;
  > select {
    > option {
      > .price-color {
        position:absolute;
      width: 15px;
      height: 15px;
      border-radius: 500px;
      border: var(--primary-border);
      &.red {
        background-color: var(--red);
      }
      &.blue {
        background-color: var(--blue);
      }
    }
    }
    
  }
}
