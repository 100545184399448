.base-auth-layout {
  margin-top: 85px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  justify-content: space-between;
  height: calc(100vh - 200px);
  max-height: 600px;
  @media screen and (max-width: 991px) {
    display: flex;
    flex-direction: column;
    height: auto;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  > .base-auth-layout-left {
    position: relative;
    width: 100%;
    padding: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--secondary-background);
    border-radius: var(--border-roundness-default);

    @media screen and (max-width: 991px) {
      padding: 30px;
    }
    @media screen and (max-width: 768px) {
      padding: 20px;
      padding-top: 70px;
    }
    > .base-auth-layout-left-top {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      > div {
        text-align: center;
      }
    }
    > .kltk-logo {
      position: absolute;
      left: 50px;
      top: 20px;
      @media screen and (max-width: 991px) {
        left: 30px;
      }
      @media screen and (max-width: 768px) {
        top: 10px;
        left: auto;
        right: auto;
      }
      width: 100px;
      height: 60px;
    }
    > .base-auth-left-children {
      width: 100%;
      @media screen and (max-width: 991px) {
        padding-top: 30px;
      }
    }
  }
  > .base-auth-layout-right {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 30px;

    > .base-auth-layout-image-wrapper {
      height: 100%;
      border-radius: var(--border-roundness-default);
      overflow: hidden;
      > img {
        width: 100%;
        object-fit: cover;
        height: 100%;
      }
      @media screen and (max-width: 991px) {
        display: none;
      }
    }
    > .base-auth-layout-contact-wrapper {
      border-radius: var(--border-roundness-default);
      padding: 15px;
      display: flex;
      flex-direction: column;
      background-color: var(--secondary-background);
      > .contact-label-link-wrapper {
        display: flex;
        justify-content: space-between;
        &.mt-5 {
          margin-top: 5px;
        }
      }
    }
  }
}
