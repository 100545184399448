@import "../../styles/circle.scss";

.popup-wrapper {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  right: 0;
  top: 0;
  z-index: 100000;
  width: 100vw;
  height: 100vh;
  > .popup {
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
    background-color: #fff;
    overflow-y: auto;
    @media screen and (max-width: 991px) {
      width: 100%;
      padding: 15px;
      padding-bottom: 150px;
    }
    > .top {
      height: 35px;
      display: flex;
      align-items: center;
      border-radius: 20px 20px 0 0;

      > .close-button-wrapper {
        display: Flex;
        margin-left: auto;
        gap: 10px;
        align-items: center;

        > .close-button {
          @include circle(35px);
          background: var(--primary-button-background);
          color: #fff;
          margin-left: auto;
          display: flex;
          align-items: center;
          justify-content: center;
          border: none;
        }
      }
    }
  }
}
