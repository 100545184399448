.week-day-selector {
    display:flex;
    align-items: center;
    gap:10px;
}
.input-desktop {
    width:135px;
}

.react-datepicker__day--selected {
    background-color: var(--toggle-button-active);
}
.react-datepicker__day:hover{
    background-color: var(--toggle-button-not-active-hover);
}
.react-datepicker__week-number--selected {
    background-color:var(--primary-button-background);;
}

.react-datepicker__navigation:hover {
    background-color: transparent;
    .react-datepicker__navigation-icon::before {
    border-color:var(--toggle-button-active-hover)
}
}
.react-datepicker__navigation-icon::before {
    border-color:var(--toggle-button-active)
}
