.button {
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px;
  &.primary {
    color: var(--hover-button-label-light);
    background-color: var(--toggle-button-not-active);
    &:hover {
      background-color: var(--toggle-button-not-active-hover);
    }
  }
  &.secondary {
    color: #ffffff;
    background-color: var(--toggle-button-active);
  }
  &.ghost {
    color: var(--primary);
    background-color: transparent;
    border: 1px solid var(--primary);
  }
}

.danger-button {
  background-color: var(--delete-color);
  &:hover:not(:disabled) {
    background-color: darkred;
  }
}
