.rehab-slots-wrapper {
  .rehab-slot-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    transition: all 0.2s ease-out;
    border-radius: var(--border-roundness-default);
    border: 1px solid transparent;
    &.is-past {
      background-color: var(--secondary-background-card);
      &:hover {
        border: 1px solid transparent;
      }
    }
    &.my-booking {
      background-color: var(--primary-button-background);
      color: #ffffff;
      &:hover {
        background-color: var(--primary-button-background);
      }
    }
    &.selected {
      background-color: var(--hover-list-background);
    }
    &.is-taken {
      background-color: var(--secondary-background-card);
      color:var(--secondary-text);
      &:hover {
        border: 1px solid transparent;
        background-color: var(--secondary-background-card);
      }
    }
    &:hover {
      background-color: var(--hover-list-background);
    }

    > .rehab-slot-time {
      display: flex;
      justify-content: space-between;
      width: 100%;
      > .choose-time {
        display: none;
      }
    }
    &:hover {
      > .rehab-slot-time {
        > .choose-time {
          display: block;
        }
      }
    }
  }
}
