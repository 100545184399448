.admin-handle-permissions-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px 15px 0px 15px;
  border-radius: 5px;

  > .handle-permissions-actions-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    align-items: center;

    > .permissions-search-wrapper {
      display: flex;
      gap: 30px;

      > .permissions-search-input {
        width: 300px;
      }
    }

    > .locations-search-wrapper {
      display: flex;
      gap: 15px;

      > .locations-search-input {
        font-size: 14px;
      }
    }
    > .permissions-add-wrapper {
      left: auto;
      position: relative;
    }
  }
}

.load-more-wrapper {
  display: flex;
  justify-content: center;
}
.permissions-table-overflow-wrapper {
  overflow-x: scroll;
  > .permissions-table {
    width: 100%;
    border-collapse: inherit;
    background-color: #ffffff;

    > thead {
      > tr {
        > th {
          &:first-child {
            padding-left: 20px;
          }

          height: 40px;
          padding: 12px 15px;
          text-align: left;
          vertical-align: middle;
          top: auto;
          bottom: auto;
        }
      }
    }

    > tbody {
      > tr {
        cursor: pointer;
        > td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border-top: var(--primary-border);
          border-right: var(--primary-border);
          vertical-align: middle;

          padding: 12px 15px;
          min-width: 200px;

          &:last-child {
            border-right: none;
            display: flex;
            justify-content: space-between;
          }
        }
      }

      > tr:hover {
        background-color: var(--hover-list-background);
      }
    }
  }
}
