.admin-handle-integrations-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px 15px 0px 15px;
  border-radius: 5px;
  > .integrations-actions-wrapper {
    display: flex;
    justify-content: space-between;

    > .integrations-actions-right {
      .save-player-name-wrapper {
        display: flex;
        gap: 10px;
      }
    }
  }
  > .admin-handle-integrations-overflow-wrapper {
    overflow-x: auto;
    padding-bottom: 15px;
    > .visual-art-integrations-table {
      width: 100%;
      > thead {
        > tr {
          > th {
            padding: 12px 15px;
            text-align: left;
            font-weight: 500;
          }
        }
      }
      > tbody {
        > tr {
          &:last-child {
            > td {
              border-bottom: var(--primary-border);
            }
          }

          > td {
            height: 40px;
            align-content: center;
            padding: 7.5px 15px;
            border-top: var(--primary-border);
            border-right: var(--primary-border);
            &:first-child {
              width: 120px;
            }
            &:nth-child(2) {
              width: 150px;
            }
            &:nth-child(3) {
              width: 150px;
            }
            &:nth-child(8) {
              width: 150px;
              > * {
                width: 100%;
              }
            }
            &:last-child {
              border-right: none;
              text-align: right;
              width: 150px;
              > * {
                width: 100%;
              }
            }
            > .player-input {
              width: 100%;
              &.disabled-input {
                background-color: #f5f5f5;
              }
            }
            > .setting-toggle-wrapper {
              > span {
                width: 50px;
              }
              display: flex;
              justify-content: start;
              align-items: center;
              gap: 10px;
            }
          }
        }
      }
    }
  }
}
