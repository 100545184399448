.clock-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.text-svg-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chosen-players {
  display: flex;
  flex-direction: column;
  gap: 10px;
  > .chosen-player {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
    align-items: center;
  }
}
.co-player-trainer-wrapper {
  > .user-booking-court-type {
    display: flex;
    flex-direction: column;
    gap: 15px;
    > .user-booking-text-toggle-wrapper {
      display: flex;
      justify-content: space-between;
      > .users-bookings-toggle-buttons {
        display: flex;
        gap: 10px;
      }
    }

    > .co-player-choices-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .player-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        transition: all 0.2s ease-out;
        border-radius: var(--border-roundness-default);
        > .player-name {
          display: flex;
          gap: 10px;
          align-items: center;
          &.frozen {
            color: red;
          }
        }
        > .player-id {
          display: flex;
        }
        > .choose-player {
          display: none;
        }
        &:hover {
          background-color: var(--hover-list-background);
          > .player-id {
            display: none;
          }
          > .choose-player {
            display: flex;
          }
        }
        @media screen and (max-width: 991px) {
          > .player-id {
            display: flex;
          }
          > .choose-player {
            display: flex;
          }
          &:hover {
            background-color: transparent;
            > .player-id {
              display: flex;
            }
            > .choose-player {
              display: flex;
            }
          }
        }
      }
    }
    > .coach-choices-wrapper {
      display: flex;
      flex-direction: column;
      gap: 10px;
      .coach-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        transition: all 0.2s ease-out;
        border-radius: var(--border-roundness-default);
        > .coach-price-id-wrapper {
          display: flex;
          gap: 10px;
          > .coach-id {
            display: flex;
          }
          > .coach-price {
            display: flex;
          }
        }

        > .choose-coach {
          display: none;
        }

        &:hover {
          background-color: var(--secondary-background-card);
          > .coach-price-id-wrapper {
            display: none;
          }
          > .choose-coach {
            display: flex;
          }
        }
        @media screen and (max-width: 991px) {
          > .coach-price-id-wrapper {
            display: flex;
          }
          > .choose-coach {
            display: flex;
          }
          &:hover {
            > .coach-price-id-wrapper {
              display: flex;
            }
            > .choose-coach {
              display: flex;
            }
          }
        }
      }
    }
  }
}

.added-coach-remove-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.color-red {
  color: red;
}
.no-latest-players {
  margin-left: 15px;
}

.add-guest-wrapper {
  display: flex;
  justify-content: flex-end;
  &.flex-row {
    flex-direction: row;
  }
}

.search-input-btn-wrapper {
  display: flex;
  gap: 10px;
  > .search-input-wrapper {
    width: 100%;
    > input {
      width: 100%;
    }
  }
}
