.admin-handle-facility-layout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: #ffffff;
  padding: 20px 15px 0px 15px;
  border-radius: 5px;

  > .handle-facility-actions-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    > .admin-handle-facility-toggle-wrapper {
      display: flex;
      gap: 10px;
    }
    > .handle-facility-buttons {
      display: flex;
      justify-content: space-between;
      gap: 10px;
      align-items: center;
    }
  }
  > .facility-times-wrapper {
    display: flex;
    flex-direction: column;
    > .facility-time-item {
      display: flex;
      gap: 20px;
      justify-content: space-between;
      align-items: center;
      padding: 12px 15px;
      max-width: 600px;
      border-bottom: var(--primary-border);
      > .facility-time-times {
        display: flex;
        align-items: center;
        gap: 10px;
      }
    }
  }
  > .facility-seasonal-batches-wrapper {
    display: flex;
    flex-direction: column;
    gap: 30px;
    > .facility-seasonal-times-wrapper {
      display: flex;
      flex-direction: column;
      > .seasonal-times-header-icon-wrapper {
        max-width: 600px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;
      }
      > .facility-time-item {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
        padding: 12px 15px;
        max-width: 600px;
        border-bottom: var(--primary-border);
        > .facility-time-times {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
  > .facility-special-days-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0px;
    padding-bottom: 30px;
    > .facility-special-days-times-wrapper {
      display: flex;
      flex-direction: column;
      > .facility-time-item {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        align-items: center;
        padding: 12px 15px;
        max-width: 600px;
        border-bottom: var(--primary-border);
        > .facility-time-day {
          min-width: 300px;
          display: flex;
          justify-content: space-between;
        }
        > .facility-time-times {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
  }
}
