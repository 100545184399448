.general-popup-wrapper {
  > .general-popup-form {
    display: flex;
    flex-direction: column;
    gap: 30px;
    &.less-gap {
      gap: 10px;
    }
    > .general-popup-form-top {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin-right: 50px;
      gap: 15px;
      margin-top: -65px;
      margin-bottom: 20px;
      position: relative;
      &.min-height-35px {
        min-height: 35px;
      }
      > .spinner-wrapper {
        position: absolute;
        top: 10px;
        bottom: 0;
        left: 90px;
      }
    }
    > .general-popup-flex-end {
      display: flex;
      justify-content: flex-end;
    }
    > .general-popup-form-group {
      display: flex;
      flex-direction: column;
      gap: 15px;
      &.mb-20 {
        margin-bottom: 20px;
      }
      > .general-popup-form-field {
        background-color: var(--secondary-background-card);
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        border-radius: var(--border-roundness-default);

        > .general-popup-flex-left {
          display: flex;
          align-items: center;
          gap: 10px;
        }
        .general-popup-flex-between {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
          &.align-top {
            align-items: flex-start;
          }
          &.flex-down {
            flex-direction: column;
            align-items: flex-start;
            gap: 10px;
          }
        }
        > .general-popup-flex-right {
          display: flex;
          align-items: center;
          gap: 10px;
          justify-content: flex-end;
        }
        .general-popup-form-field-divider {
          display: flex;
          flex-direction: column;
          gap: 20px;
          > .general-popup-form-field-item {
            display: flex;
            justify-content: space-between;
            gap: 20px;
          }
          > .general-popup-form-field-divided-section {
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-radius: var(--border-roundness-default);

            &.flex-between {
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
          }
        }
      }
    }
    > .general-popup-lila-border-group {
      padding: 15px;
      border-radius: var(--border-roundness-default);
      border: 1px solid var(--primary-button-background);
      height: 100%;
    }

    > .general-popup-bottom-buttons-wrapper {
      display: flex;
      gap: 15px;
      justify-content: space-between;
      align-items: center;
      &.flex-end {
        justify-content: flex-end;
      }
    }
    .general-popup-danger-buttons-wrapper {
      position: relative;
      top: auto;
      display: flex;
      flex-direction: column;
      gap: 15px;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}

.general-popup-gap-10 {
  gap: 10px;
  display: flex;
}
