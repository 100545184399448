.admin-activities-page-container {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  .admin-activities-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff;
    padding: 20px 15px 0px 15px;
    border-radius: 5px;
    width: 100%;
    .handle-activities-actions-wrapper {
      display: flex;

      gap: 30px;
      align-items: center;
      > .admin-handle-activity-toggle-wrapper {
        display: flex;
        gap: 10px;
      }
      .activities-search-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
        .activities-search-input {
          width: 200px;
        }
      }
      .activities-add-wrapper {
        display: flex;
        gap: 10px;
        align-items: center;
      }
    }
    > .activities-table-overflow-wrapper {
      overflow-x: auto;
      padding-bottom: 15px;
      > .activities-table {
        width: 100%;
        > tbody {
          > tr {
            > td {
              align-content: flex-start;
              padding: 0px 1.5px;
            }
          }
        }
      }
    }
  }
}

.activities-table {
  width: 100%;
  border-collapse: inherit;
  background-color: #ffffff;

  > thead {
    > tr {
      transition: all 0.2s ease-out;
      > th {
        height: 40px;
        padding: 12px 15px;
        text-align: left;
        vertical-align: middle;
        top: auto;
        bottom: auto;
      }
    }
  }

  > tbody {
    > tr {
      cursor: pointer;
      transition: all 0.2s ease-out;
      > td {
        &:first-child {
          min-width: 100px;
        }

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        border-top: var(--primary-border);
        border-right: var(--primary-border);
        vertical-align: middle;

        padding: 12px 15px;
        min-width: 200px;

        &:last-child {
          border-right: none;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    > tr:hover {
      background-color: var(--hover-list-background);
    }
  }
}
