@import "../../styles/variables.scss";

.app-wrapper {
  position: relative;
  min-width: 1320px;
  max-width: 1920px;
  margin: auto;
  padding: 0 30px;
  @media screen and (max-width: 991px) {
    padding: 0 15px;
    min-width: 0;
  }
  > .content {
    padding-top: 15px;
    padding-bottom: 50px;
  }
}

.app-wrapper-admin {
  max-width: 2400px !important;
}
