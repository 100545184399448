.coach-times-page-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  .coach-times-layout {
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: #ffffff;
    padding: 20px 15px 0px 15px;
    border-radius: 5px;
    width: 100%;

    > .coach-table-overflow-wrapper {
      @media screen and (max-width: 991px) {
        overflow-x: scroll;
      }
      > .coach-table {
        width: 100%;

        > thead {
          > tr {
            > th {
              &:first-child {
                padding-left: 20px;
              }
              height: 40px;
              padding: 12px 15px;
              text-align: center;

              vertical-align: middle;
              top: auto;
              bottom: auto;
              @media screen and (max-width: 991px) {
                text-align: center;
              }
            }
          }
        }
        > tbody {
          > tr {
            > td {
              width: 14.2857%;
              @media screen and (max-width: 991px) {
                width: 100%;
                min-width: 200px;
              }
              align-content: flex-start;
              padding: 0px 1.5px;
              > .thirty-minuite-slot {
                height: 55px;
              }
            }
          }
        }
      }
    }

    > .coach-times-declarations {
      display: flex;
      justify-content: space-between;
      > .declarations-wrapper {
        display: flex;
        gap: 20px;
        color: var(--secondary-text);

        @media screen and (max-width: 991px) {
          display: none;
        }
      }
    }
    > .coach-times-action-wrapper {
      display: flex;
      justify-content: space-between;
      gap: 30px;
      align-items: center;
      > .coach-times-weekday-selector-wrapper {
        display: flex;
        gap: 10px;
        flex-direction: row;
        align-items: center;
      }
      > .coach-availability-action-price-wrapper {
        display: flex;
        gap: 30px;
        align-items: center;
        > .coach-times-prices-wrapper {
          @media screen and (max-width: 991px) {
            display: none;
          }
          display: flex;
          gap: 50px;
          > .coach-times-default-prices {
            display: flex;
            gap: 20px;
            > .coach-times-price-wrapper {
              padding: 9px 15px;
              border: var(--primary-border);
              display: flex;
              justify-content: space-between;
              min-width: 200px;
              border-radius: 500px;
            }
          }
        }
      }
    }
  }
  .coach-times-maker-wrapper {
    overflow: hidden;
    min-width: 370px;
    display: flex;
    flex-direction: column;
    background-color: var(--secondary-background);
    border-radius: var(--border-roundness-default);
    > .coach-times-top-wrapper {
      display: flex;
      gap: 15px;
      flex-direction: column;
      background-color: var(--secondary-background);
      padding: 15px;

      > .coach-times-court-type {
        display: flex;
        gap: 10px;
        > .pseudo-button {
          background-color: var(--toggle-button-not-active);
          display: flex;
          justify-content: center;
          align-items: center;
          border: none;
          border-radius: 40px;
          color: var(--secondary-button-label);
          padding: 9px 15px;
          height: 35px;
          &.smaller {
            height: 25px;
          }
          &.wide {
            min-width: 200px;
          }
          &.active {
            background-color: var(--toggle-button-active);
            color: var(--primary-button-label);
          }
        }
      }
      > .coach-times-booking-type {
        display: flex;
        flex-direction: column;
        gap: 10px;
        > .coach-times-specifics {
          display: flex;
          flex-direction: column;
          padding: 5px 0px;
          background-color: var(--secondary-background-card);
          border-radius: var(--border-roundness-default);
          > .coach-times-info-row-wrapper {
            padding: 5px 15px;
            display: flex;
            justify-content: space-between;
            > .coach-times-action {
              width: 100%;
              margin-top: 10px;
              > .full-width {
                width: 100%;
              }
            }
          }
        }
      }
    }

    > .coach-times-bottom-default {
      border-top: 1px solid var(--primary-border-color);
      display: flex;
      gap: 15px;
      flex-direction: column;
      background-color: var(--secondary-background);
      padding: 15px;
      > .coach-times-specifics {
        display: flex;
        flex-direction: column;
        padding: 5px 0px;
        background-color: var(--secondary-background-card);
        border-radius: var(--border-roundness-default);
        > .coach-times-info-row-wrapper {
          padding: 5px 15px;
          display: flex;
          justify-content: space-between;
          > .info-row-inner-gap {
            display: flex;
            align-items: center;
            gap: 10px;
          }
          > .coach-times-action {
            width: 100%;
            > .full-width {
              width: 100%;
            }
          }
        }
      }
    }
    > .coach-times-bottom-wrapper {
      padding: 15px;
      border-radius: var(--border-roundness-default);
      border: 1px solid var(--primary-button-background);
      height: 100%;
    }
  }
}

.coach-table-body {
  > tr {
    > td {
      &:nth-child(1) {
        width: 20%;
      }
      padding: 0px 0px;

      > .time-item-wrapper {
        margin-bottom: 1.5px;
        > .time-content-wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;
          gap: 3px;
          max-height: 55px;
        }
        &.selected-slot {
          background-color: var(--primary-button-background);
          color: var(--primary-button-label);
        }
        &.not-available {
          background-color: rgb(174, 174, 174);
        }
        &.available {
          background-color: var(--secondary-background);
        }
        height: 55px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        border-radius: var(--border-roundness-default);
        border: var(--primary-border);
        background-color: #ffffff;
        padding: 7px 5px;
        min-width: 66px;

        &.slot-booked {
          color: #ffffff;
          background-color: var(--primary-button-background);
        }
      }
    }
  }
}

.taken-icon {
  width: 15px;
  height: 15px;
}
